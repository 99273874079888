.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-family: Arial, sans-serif;
}

.chatbot-bubble {
  width: 60px;
  height: 60px;
  background-color: #4a90e2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.chatbot-window {
  width: 300px;
  height: 400px;
  border: 1px solid;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto; /* Changed to allow resizing */
  resize: both; /* Allows the window to be resizable */
}

.chatbot-header {
  display: flex; /* Added for alignment */
  align-items: center; /* Center-aligns content */
  padding: 10px;
  color: white;
}

.chatbot-header-buttons {
  margin-left: auto; /* Pushes buttons to the right */
  display: flex;
}

.chatbot-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  margin-left: 5px;
}

.chatbot-messages {
  flex: 1;
  display: flex; /* Added for message alignment */
  flex-direction: column; /* Aligns messages vertically */
  padding: 10px;
  overflow-y: auto;
  background-color: #fff;
}

.message {
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 20px;
  max-width: 80%;
}

.message.user {
  background-color: #4a90e2;
  color: white;
  align-self: flex-end; /* Aligns to the right */
}

.message.bot {
  background-color: #e0e0e0;
  align-self: flex-start; /* Aligns to the left */
}

.chatbot-input {
  display: flex;
  border-top: 1px solid #ccc;
}

.chatbot-input input {
  flex: 1;
  padding: 10px;
  border: none;
  outline: none;
}

.chatbot-input button {
  padding: 10px;
  background-color: #4a90e2;
  color: white;
  border: none;
  cursor: pointer;
}

.chatbot-input button:hover {
  background-color: #3a78c2;
}

.chatbot-messages.div.p {
  margin: 0;
}